(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name neo.services.auth.factory:Tokens
   *
   * @description
   *
   */
  angular
  .module('neo.services.auth')
  .factory('Tokens', Tokens);

  function Tokens($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/auth/tokens/:token', {token: '@_token'});
  }
}());
